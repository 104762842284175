var ArrowDisabler = function (Glide, Components, Events) {
    return {
        mount() {
            if (Glide.settings.rewind) return;

            Glide.on(["mount.after", "run"], () => {
                for (let controlItem of Components.Controls.items) {
                    if (controlItem.className !== "glide__arrows") continue;

                    var left = controlItem.querySelector(".glide__arrow--left");
                    var right = controlItem.querySelector(
                        ".glide__arrow--right"
                    );

                    if (left) {
                        if (Components.Sizes.length <= Glide.settings.perView) {
                            left.setAttribute("disabled", "");
                        } else if (Glide.index === 0) {
                            left.setAttribute("disabled", "");
                        } else {
                            left.removeAttribute("disabled");
                        }
                    }

                    if (right) {
                        if (Components.Sizes.length <= Glide.settings.perView) {
                            right.setAttribute("disabled", "");
                        } else if (
                            Glide.index ===
                            Components.Sizes.length - Glide.settings.perView
                        ) {
                            right.setAttribute("disabled", "");
                        } else {
                            right.removeAttribute("disabled");
                        }
                    }
                }
            });
        },
    };
};

const landingNavigationLinks = document.querySelectorAll(
    ".landing-navigation-link"
);

landingNavigationLinks.forEach((link) => {
    link.addEventListener("click", (event) => {
        event.preventDefault();
        const id = "block-" + link.getAttribute("href").substring(1);
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
                offsetTop: 0, // Adjust the offset value as needed
            });
        }
    });
});

jQuery(function ($) {
    //Mobile menu drop-down
    $("#header nav ul li.megaMenu.menu-item-has-children").click(function (e) {
        if ($(window).width() < 992) {
            // Check if the clicked element is the top-level megaMenu li
            if ($(this).hasClass("megaMenu")) {
                e.preventDefault(); // Prevent default only for the top-level li
            }

            if ($(this).hasClass("open")) {
                // If the clicked item already has the 'open' class, close its submenu
                $(this).removeClass("open").children("ul").slideUp(250);
            } else {
                // If the clicked item does not have the 'open' class, close others and open its submenu
                $(this).siblings(".open").find("ul").slideUp(250);
                $(this).siblings(".open").removeClass("open");
                $(this).addClass("open").children("ul").slideDown(250);
            }
        }
    });

    // Allow clicks on the links within the megaMenu sub-menu
    $("#header nav ul li.megaMenu.menu-item-has-children > ul > li").click(
        function (e) {
            // Stop propagation to prevent the click event from reaching the parent li
            e.stopPropagation();
        }
    );

    $("#header #menu-toggle").click(function () {
        $(this).toggleClass("open");
        $("#header nav .open").removeClass("open").find("ul").slideUp(250);
        $("#header nav").stop().slideToggle(250);
    });

    $("#solution-dropdown div").click(function () {
        $("#solution-dropdown ul").stop().slideDown(250);
    });

    $(document).on("click", function (e) {
        if (
            !$("#solution-dropdown").is(e.target) &&
            !$("#solution-dropdown").has(e.target).length
        ) {
            $("#solution-dropdown ul").stop().slideUp(250);
        }
    });

    if ($(".block-image_and_quotes").length) {
        quoteSlider = new Glide(".block-image_and_quotes .glide", {
            type: "carousel",
            autoplay: 5000,
        }).mount();
    }

    if ($(".block-testimonials").length) {
        testimonialSlider = new Glide(".block-testimonials .glide", {
            type: "carousel",
            autoplay: 6000,
            animationDuration: 800,
            rewind: false,
            perView: 2,
            gap: 30,
            peek: { before: 0, after: 200 },
            breakpoints: {
                1280: {
                    perView: 1,
                    gap: 30,
                    peek: { before: 0, after: 200 },
                },
                991: {
                    perView: 1,
                    gap: 0,
                    peek: { before: 0, after: 0 },
                },
            },
        }).mount();
    }

    if ($(".block-inline_video_carousel").length) {
        $(".glide").each(function () {
            var carouselId = $(this).attr("id");

            if (carouselId) {
                var glideInstance = new Glide("#" + carouselId, {
                    type: "carousel",
                    gap: 30,
                    perView: 1,
                    peek: { before: 0, after: 0 },
                }).mount();
            }
        });
    }

    $("body").on("click", ".tessellateConsent", function (event) {
        event.preventDefault();
        $(".cs-info-sticky-button").click();
    });

    if ($("#comparison").length && $("#comparison-content").length) {
        if ($("#comparison").find(".glide__slide").length > 1) {
            var id = $("#comparison").find(".glide").attr("id");
            compareSlider = new Glide("#" + id, {
                perView: 3,
                gap: 20,
                rewind: false,
                swipeThreshold: false,
                dragThreshold: false,
                breakpoints: {
                    991: {
                        perView: 1,
                        gap: 0,
                    },
                },
            }).mount({ ArrowDisabler });

            var id = $("#comparison-content").find(".glide").attr("id");
            compareContentSlider = new Glide("#" + id, {
                perView: 3,
                gap: 20,
                rewind: false,
                swipeThreshold: false,
                dragThreshold: false,
                breakpoints: {
                    991: {
                        perView: 1,
                        gap: 0,
                    },
                },
            }).mount();

            compareSlider.on("run", function (e) {
                compareContentSlider.go(e.direction);
            });

            compareContentSlider.on("run", function (e) {
                compareSlider.go(e.direction);
            });
        }
    }

    $(".faq h3").click(function () {
        $(this).parent().toggleClass("open").find("p").stop().slideToggle(250);
    });

    $(".shortcode-wrapper .accordion-title").click(function () {
        $(this)
            .parent()
            .toggleClass("open")
            .find(".panel")
            .stop()
            .slideToggle(250);
    });

    $(".finance-button").click(function (e) {
        e.preventDefault();
        $("html, body").css("overflow", "hidden");
        $("#finance-modal").stop().fadeIn(250);
    });

    $(".quote-button").click(function (e) {
        e.preventDefault();
        $("html, body").css("overflow", "hidden");
        $("#quote-modal").stop().fadeIn(250);
    });

    $(document).on("click", function (e) {
        if ($(".modal").is(e.target) || $(".modal-close").is(e.target)) {
            e.preventDefault();
            $("html, body").css("overflow", "");
            $(".modal").stop().fadeOut(250);
        }
    });

    $(window).resize(function () {
            
        var tabs = $("section#tabs");
        var tabsph = $("section#tabs-placeholder");
        var header = $("#header");
        var headerph = $("#header-placeholder");
        var accordion = $(".services-accordion-container");
        var accordionph = $(".services-accordion-container-placeholder")
        tabs.removeClass("fixed");
        tabsph.css("height", "");
        header.removeClass("fixed");
        headerph.css("height", "");
        Waypoint.destroyAll();
        if ($(window).width() >= 992) {
            if (headerph.length) {
                headerph.waypoint(
                    function (direction) {
                        if (direction === "up") {
                            header.removeClass("fixed");
                            headerph.css("height", "");
                        } else {
                            header.addClass("fixed");
                            headerph.css("height", 108);
                        }
                    },
                    {
                        offset: -1,
                    }
                );
            }
            if (tabsph.length) {
                tabsph.waypoint(
                    function (direction) {
                        if (direction === "up") {
                            tabs.removeClass("fixed");
                            tabsph.css("height", "");
                        } else {
                            tabs.addClass("fixed").css("top", 74);
                            tabsph.css("height", tabs.height());
                        }
                    },
                    {
                        offset: 74,
                    }
                );
            }
        } 
        
        if ($(window).width() <= 991) {
            if (accordionph.length) {
                accordionph.waypoint(
                    function (direction) {
                        if (direction === "up") {
                            accordion.removeClass("fixed");
                            accordionph.css("height", "");
                        } else {
                            accordion.addClass("fixed").css("top", 0);
                            accordionph.css("height", 0);
                        }
                    },
                    {
                        offset: 0,
                    }
                );
            }
        }
    })
    .resize()

    $(document).ready(function () {
        $(".accordion-header").click(function () {
            $(this).toggleClass("active");
            $(this).next(".accordion-body").slideToggle();
        });
    });
});
